.blog-section {
    img {
        height: 400px;
        object-fit: cover;
        width: 100%;

        @media (max-width: 575.98px) {
            height: 250px;
        }
    }

    .full-width {
        height: 100%;
        max-height: 650px;
    }

    h2 {
        height: 70px;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        margin: 0 auto;
        /* font-size: 14px; */
        line-height: 1.5;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    p {
        display: block;
        display: -webkit-box;
        max-width: 100%;
        margin: 0 auto;
        /* font-size: 14px; */
        line-height: 1.5;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.img-blog-details {
    height: auto;
    width: 100%;
    object-fit: cover;
}

.rotate-slash {
    transform: rotate(75deg);
}

.rotate-line {
    transform: rotate(75deg);
}

.ml-m-20 {
    margin-left: -20px;
    margin-top: -10px;
}

.mt-m-10 {
    margin-top: -10px;
}

.max-height {
    max-height: 580px !important;
}