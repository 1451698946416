@import "../variables/variables";

.about-img {
    width: 100%;
    height: 550px;
    object-fit: cover;
    border-radius: 3px;
}

.social-icons-large {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        display: inline-block;
        margin-right: 10px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $color-orange-dark;
        color: $color-white;
        text-align: center;
        box-shadow: $shadow-lg;
    }

    .icons {
        color: $color-white;
        line-height: 2;
        font-size: 24px;
    }
}