@import "../variables/variables";

/*--------------------Default-------------------------*/
.color-white {
  color: $color-white !important;
}

.image-overlay {
  position: relative;
}

.image-overlay img {
  width: 100%;
  vertical-align: top;
}

.image-overlay:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  opacity: 1;
}

p {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  color: $color-light-black;

  @media (min-width: 576px) {
    $map: (576px: 14px,
        768px: 14px,
      );
    font-size: linear-interpolation($map);
  }

  @media (min-width: 768px) {
    $map: (768px: 14px,
        992px: 16px,
      );
    font-size: linear-interpolation($map);
  }

  @media (min-width: 992px) {
    font-size: 16px;
  }
}

section {
  padding: 100px 0;
}

.bg-color-1 {
  background-color: $color-bg-1;
}

.bg-color-2 {
  background: rgb(253, 128, 45);
  background: linear-gradient(0deg,
      rgba(253, 128, 45, 0.32816876750700286) 0%,
      rgba(253, 128, 45, 0.0844712885154062) 100%);
}

.bg-light-gray {
  background-color: $color-light-gray-1;
}

.bg-light-gray-1 {
  background-color: $color-gray;
}

.wave {
  width: 100%;
  object-fit: cover;
}

.rotate-180 {
  transform: rotate(180deg);
  margin-top: -1px;
}

.hide {
  display: none;
}

.br-10 {
  border-radius: 10px;
}

/*--------------------title-------------------------*/
.title {
  font-size: 22px;
  font-weight: bold;
  color: $color-charcoal;
  // font-family: "Roboto", sans-serif;
  font-family: "Lora", serif;

  @media (min-width: 576px) {
    $map: (576px: 22px,
        768px: 24px,
      );
    font-size: linear-interpolation($map);
  }

  @media (min-width: 768px) {
    $map: (768px: 24px,
        992px: 36px,
      );
    font-size: linear-interpolation($map);
  }

  @media (min-width: 992px) {
    font-size: 36px;
  }

  span {
    background-image: linear-gradient(135deg, #fec163 10%, #de4313 100%);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}

.sub-title {
  font-size: 16px;
  font-weight: bold;
  color: $color-charcoal;
  // font-family: "Roboto", sans-serif;
  font-family: "Lora", serif;

  @media (min-width: 576px) {
    $map: (576px: 18px,
        768px: 20px,
      );
    font-size: linear-interpolation($map);
  }

  @media (min-width: 768px) {
    $map: (768px: 20px,
        992px: 24px,
      );
    font-size: linear-interpolation($map);
  }

  @media (min-width: 992px) {
    font-size: 24px;
  }


}

.title-small {
  font-size: 18px;
  font-weight: bold;
  color: $color-charcoal;
  // font-family: "Roboto", sans-serif;
  font-family: "Lora", serif;

  @media (min-width: 576px) {
    $map: (576px: 18px,
        768px: 20px,
      );
    font-size: linear-interpolation($map);
  }

  @media (min-width: 768px) {
    $map: (768px: 20px,
        992px: 24px,
      );
    font-size: linear-interpolation($map);
  }

  @media (min-width: 992px) {
    font-size: 24px;
  }

  span {
    background-image: linear-gradient(135deg, #fec163 10%, #de4313 100%);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}

.big-title {
  font-size: 22px;
  font-weight: 900;
  color: $color-charcoal;
  font-family: "Lora", serif;
  font-style: italic;

  @media (min-width: 576px) {
    $map: (576px: 22px,
        768px: 24px,
      );
    font-size: linear-interpolation($map);
  }

  @media (min-width: 768px) {
    $map: (768px: 24px,
        992px: 3.5rem,
      );
    font-size: linear-interpolation($map);
  }

  @media (min-width: 992px) {
    font-size: 3.5rem;
  }
}

.big-special-title {
  font-size: 25px;
  font-weight: bold;
  color: $color-charcoal;
  font-family: "Lora", serif;

  @media (min-width: 576px) {
    $map: (576px: 25px,
        768px: 30px,
      );
    font-size: linear-interpolation($map);
  }

  @media (min-width: 768px) {
    $map: (768px: 30px,
        992px: 6rem,
      );
    font-size: linear-interpolation($map);
  }

  @media (min-width: 992px) {
    font-size: 6rem;
  }
}

.sub-small-title {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 0;
  color: $color-orange-dark;
}

.special-title {
  font-family: "Niconne", cursive;
}

.number-lists {
  list-style: decimal;

  li {
    padding-bottom: 20px;

  }
}

.dots-lists {
  list-style: disc;

  li {
    padding-bottom: 20px;

  }
}

p {
  b {
    font-weight: bolder;
    color: #000;
    letter-spacing: normal;
  }
}