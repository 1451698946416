@import "node_modules/react-modal-video/scss/modal-video.scss";

// ---------- Covid Card ---------- //
.covid-banner-section {
  background-color: $color-gray;
}

.covid-card {
  border-radius: 10px;
  border: 0;
  padding: 0;
  margin: 0;
  height: auto;

  .covid-banner-image {
    width: 100%;
    object-fit: cover;
    height: auto;
    border-radius: 10px;
  }

  .card-body {
    padding: 0;

    p {
      color: #eeeaeabd;
      font-weight: 900;
      letter-spacing: 1px;
    }

    .banner-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }

    .line {
      border-top: 1px solid rgb(255, 255, 255);
    }
  }

  .image-overlay {
    &::after {
      border-radius: 10px;
    }
  }
}

// ---------- Events Card ---------- //
.events-card {
  border: 0;
  box-shadow: $shadow;

  .textMinimum {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .card-title {
    font-family: "Lora", serif;
    font-style: italic;
    font-size: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* number of lines to show */
    -webkit-box-orient: vertical;
    height: 55px;
    color: $color-charcoal;
  }

  .linkTitle,
  .linkText {
    &:hover {
      text-decoration: none;
    }
  }

  .linkText {
    margin-bottom: 20px;
  }

  .card-text {
    font-family: "Noto Sans", sans-serif;
  }

  span {
    color: $color-light-black;
    font-size: 12px;
  }

  .card-body {
    svg {
      font-size: 16px;
      margin-right: 5px;
      margin-top: -3px;
    }
  }

  .card-footer {
    background-color: #fff;
  }

  .eventCardImg {
    height: 300px;
    object-fit: cover;
  }

  .eventCardImgNew {
    height: 350px;
    object-fit: cover;
  }
}

// ---------- Video Card ---------- //
.video-section {
  background-image: url("../../img/backgrounds/iandyou.jpg");
  background-repeat: no-repeat;
  background-position: bottom;
  height: auto;
  background-size: 120%;
}

.video-card {
  margin-top: 60px;
  border-radius: 10px;
  box-shadow: $shadow;

  img {
    width: 100%;
    height: 400px;
    border-radius: 10px;
    object-fit: cover;
  }

  .video-card-content {
    position: absolute;
    top: 50px;
    margin: 50px;
    color: $color-white;

    h2 {
      color: $color-white;
      width: 60%;
      font-family: $lora;

      span {
        font-style: italic;
      }
    }

    p {
      color: $color-white;
      width: 60%;
    }

    p {
      margin-bottom: 30px;
    }
  }

  .play-video-button {
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 10%;
    cursor: pointer;
  }

  .image-overlay {
    &:after {
      border-radius: 10px !important;
      background: rgba(0, 0, 0, 0.46) !important;
    }
  }
}

// ---------- Pulse Icon ---------- //
/*main element*/
.pulse-icon {
  position: relative;
  display: inline-block;
}

/*icon styling here*/
.pulse-icon .icon-wrap {
  position: relative;
  width: 60px;
  height: 60px;
  box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  color: #6a1b9a;
  background: #efefef;
  position: relative;
  z-index: 2;
  line-height: 3.6;
  color: $color-orange-dark;
}

.pulse-icon .icon {
  line-height: 60px;
  font-size: 1.75em;
  animation: grow-shrink 4s ease-out infinite;
}

/*container of all circles and pulses*/
.pulse-icon .elements {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.pulse-icon .circle {
  border-radius: 50%;
  position: absolute;
}

.pulse-icon .circle-inner {
  width: 80px;
  height: 80px;
  top: -10px;
  left: -10px;
  background: linear-gradient(-180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.1) 100%);
  animation: grow-circles 4s linear infinite both;
}

.pulse-icon .circle-outer {
  width: 100px;
  height: 100px;
  background: linear-gradient(-180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.1) 100%);
  top: -20px;
  left: -20px;
  animation: grow-circles 4s linear infinite both;
}

/*series of x pulses of differing sizes*/
.pulse-icon .pulse {
  position: absolute;
  animation: pulse-wave 4s linear infinite both;
  border-radius: 50%;
}

.pulse-icon .pulse-1 {
  border: solid 1px rgba(255, 255, 255, 0.25);
  width: 200px;
  height: 200px;
  top: -70px;
  left: -70px;
}

.pulse-icon .pulse-2 {
  border: solid 1px rgba(255, 255, 255, 0.35);
  width: 300px;
  height: 300px;
  top: -120px;
  left: -120px;
}

.pulse-icon .pulse-3 {
  border: solid 1px rgba(255, 255, 255, 0.45);
  width: 400px;
  height: 400px;
  top: -170px;
  left: -170px;
}

/*Animation for the outewards pulsating waves*/
@keyframes pulse-wave {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }

  50% {
    opacity: 1;
    transform: scale(0.5);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

/*animation for the inner and outer wave items to grow*/
@keyframes grow-circles {
  0% {
    opacity: 0.85;
    transform: scale(1);
  }

  15% {
    opacity: 1;
    transform: scale(1.05);
  }

  30% {
    opacity: 0.85;
    transform: scale(1);
  }
}

/*animation for the icon to slightly grow and shrink*/
@keyframes grow-shrink {
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(1.1);
  }

  30% {
    transform: scale(1);
  }
}

// ---------- Testimonial Icon ---------- //
.testimonials-section {
  background-color: $color-bg-2;
}

.testimonials-card {
  padding: 20px;
  border: 0;
  box-shadow: $shadow;
  border-radius: 10px;

  .testimonials {
    height: 200px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .br-1 {
    border-right: 3px solid #dddbdb4d !important;
  }

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    box-shadow: $shadow;
    border-radius: 50%;
    margin-right: 10px;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    p {
      margin-bottom: 0;
      padding-left: 10px;
    }

    .client {
      font-family: $nato;
      font-weight: 700;
    }

    .designation {
      font-size: 12px;
      font-family: $nato;
      font-weight: 700;
    }
  }
}

// ---------- Twitter Icon ---------- //
#twitter-widget-0 {
  border: 1px solid $color-gray !important;
  border-radius: 10px;
  box-shadow: $shadow-lg;
}

// ---------- Registration Icon ---------- //
.br-3 {
  border-right: 3px solid $color-light-gray-1 !important;
}

.registration-card {
  border: 0;
  border-radius: 10px;
  box-shadow: $shadow-lg;
  background-image: linear-gradient(135deg, #de4313 10%, #fec163 100%);
  height: auto;

  h2 {
    color: $color-white;
    text-align: left;
    padding-bottom: 20px;
  }

  h3 {
    font-family: $nato;
    font-weight: 600;
    color: $color-white;
    font-size: 16px;
  }

  p,
  li {
    color: $color-white;
    font-size: 14px;
  }

  padding: 50px;

  .btn {
    width: 150px;
    border: 1px solid $color-white;
  }

  .pattern-bg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;

    img {
      opacity: 0.1;
    }
  }

  .content {
    z-index: 111;
    position: relative;
  }
}

// ---------- Rotate Animation ---------- //
.rotateme {
  animation-name: rotateme;
  animation-duration: 40s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: rotateme;
  -webkit-animation-duration: 40s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotateme;
  -moz-animation-duration: 40s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: rotateme;
  -ms-animation-duration: 40s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: rotateme;
  -o-animation-duration: 40s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@keyframes rotateme {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotateme {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotateme {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(360deg);
  }
}

//  ---------- I and you card ----------//
.iandyou-card-mobile {
  img {
    width: 100%;
    height: 450px;
    object-fit: cover;
  }

  .content {
    font-family: "Lora", serif;
    font-style: italic;
    font-weight: 900;
    font-size: 5rem;
    color: #fff;
    text-align: center;
    bottom: 10%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 2px 2px 4px #000000;
  }
}

.iandyou-card-mobile {
  padding-bottom: 50px;

  .gradient-text {
    vertical-align: middle;
    // background-color: #3b3e85;
    // background-image: linear-gradient(135deg, #3b3e85 10%, #000dff 100%);
    background-image: linear-gradient(135deg, #f17c2b 10%, #de4313 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  .line {
    width: 150px;
    height: auto;
  }
}

.iandyou-card-section {
  .gradient-text {
    vertical-align: middle;
    // background-color: #3b3e85;
    // background-image: linear-gradient(135deg, #3b3e85 10%, #000dff 100%);
    background-image: linear-gradient(135deg, #f17c2b 10%, #de4313 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  .line {
    width: 275px;
  }

  background-color: $color-gray;

  .title {
    font-family: $lora;
    font-style: italic;
    font-weight: 900;
    font-size: 5rem;
    color: $color-white;
    text-align: center;
    bottom: 10%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-shadow: 2px 2px 4px #000000;
  }

  h3 {
    font-size: 4rem;
  }
}

.i-and-you-card {
  img {
    height: 450px;
    object-fit: cover;
    width: 100%;
  }
}

.image-grid {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  max-width: 80%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.image-grid__item {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 360px;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  height: 500px;
  // margin-right: 14px;
  // margin-bottom: 14px;
  -webkit-transition: text-shadow 0.1s ease-in, -webkit-transform 0.14s ease-in;
  transition: text-shadow 0.1s ease-in, -webkit-transform 0.14s ease-in;
  transition: transform 0.14s ease-in, text-shadow 0.1s ease-in;
  transition: transform 0.14s ease-in, text-shadow 0.1s ease-in, -webkit-transform 0.14s ease-in;
}

.image-grid__item:before {
  content: "";
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: none;
  border-radius: 3px;
  box-shadow: 0 10px 24px 0px rgba(0, 0, 0, 0.06), 0 8px 20px -2px rgba(0, 0, 0, 0.1),
    0 6px 10px -6px rgba(0, 0, 0, 0.2);
  -webkit-transition: visibility 0.1s ease-out, opacity 0.1s ease-out;
  transition: visibility 0.1s ease-out, opacity 0.1s ease-out;
  opacity: 0;
}

.image-grid__item:hover:before {
  visibility: visible;
  opacity: 1;
}

.grid-item {
  display: -webkit-box;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
  color: #eeeeee;
  overflow: hidden;
}

.grid-item:hover .grid-item__image {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.grid-item:hover .grid-item__hover {
  visibility: visible;
  opacity: 1;
}

.grid-item:hover .grid-item__name {
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.grid-item__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  -webkit-transform: scale(1);
  transform: scale(1);
  will-change: transform;
  -webkit-transition: -webkit-transform 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: -webkit-transform 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    -webkit-transform 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 0;
}

.grid-item__hover {
  visibility: hidden;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(rgba(47, 48, 50, 0.2), rgba(47, 48, 50, 0.7));
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.15);
  border-radius: 0;
  -webkit-transition: visibility 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94),
    opacity 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: visibility 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 0;
}

.grid-item__name {
  visibility: hidden;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  -webkit-box-align: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 300;
  text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4), 2px 2px 6px rgba(0, 0, 0, 0.3);
  -webkit-box-pack: center;
  justify-content: center;
  letter-spacing: 1px;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transition: visibility 0.14s ease-out, opacity 0.14s ease-out, -webkit-transform 0.24s ease;
  transition: visibility 0.14s ease-out, opacity 0.14s ease-out, -webkit-transform 0.24s ease;
  transition: visibility 0.14s ease-out, opacity 0.14s ease-out, transform 0.24s ease;
  transition: visibility 0.14s ease-out, opacity 0.14s ease-out, transform 0.24s ease, -webkit-transform 0.24s ease;
  opacity: 0;
}

.center_item {
  flex-basis: 400px;
  height: 600px;
  z-index: 111;
}

/* Media Queries */
@media screen and (max-width: 960px) {
  .parent {
    width: 100%;
    margin: 20px 0px;
  }

  .wrapper {
    padding: 20px 20px;
  }
}

.hello {
  display: none;
}

// ---------- Register Card ---------- //
.gregister-dark-card-bg-img {
  text-align: right;

  img {
    height: 590px;
    border-radius: 10px;
    object-fit: cover;
    width: 70%;
  }

  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
}

.register-dark-card {
  max-width: 75%;
  position: relative;
  z-index: 1111;
  width: auto;
  padding: 100px 80px;
  background-color: $color-charcoal;

  border-radius: 10px;
  box-shadow: $shadow-lg;

  h2 {
    background-image: linear-gradient(135deg, #fec163 10%, #de4313 100%);
    background-size: 100%;
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    font-family: $lora;
    font-style: italic;
  }

  p {
    color: $color-white;
  }
}

// ---------- Project Cards ----------//
%transition {
  transition: all 0.4s ease-in-out 0s;
}

.blog-style--1 {
  position: relative;
  margin-bottom: 40px;
  @extend %transition;
  overflow: hidden;
  border-radius: 6px;

  .btnOutline {
    border: 2px solid $color-white;
  }

  .thumbnail {
    a {
      display: block;
      position: relative;
      z-index: 1;

      img {
        @extend %transition;
      }

      &::after {
        // background: linear-gradient(to bottom, #f55555, #000000 100%);
        background: linear-gradient(to bottom, #fc004d 0, #000000 100%);
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        content: "";
        z-index: 1;
        opacity: 0;
        @extend %transition;
        // @media #{$large-mobile} {
        //     opacity: 0.85;
        //     top: 0;
        // }
      }
    }
  }

  .content {
    position: absolute;
    bottom: -92px;
    width: 100%;
    padding: 50px 35px;
    z-index: 3;
    @extend %transition;

    // @media #{$large-mobile} {
    //     bottom: 0;
    // }
    // @media #{$large-mobile} {
    //     padding: 30px 20px;
    // }
    &::before {
      position: absolute;
      content: "";
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, #000000 70%);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      @extend %transition;
      z-index: -1;
      // @media #{$large-mobile} {
      //     opacity: 0;
      // }
    }

    p {
      &.blogtype {
        font-size: 14px;
        margin-bottom: 5px;
        color: #c6c9d8;
      }
    }

    h4 {
      &.title {
        margin-bottom: 0;

        a {
          color: #ffffff;
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          @extend %transition;
        }
      }
    }

    .blog-btn {
      @extend %transition;
      margin-top: 35px;
      transition: 0.7s;
      opacity: 0;
      visibility: hidden;

      // @media #{$large-mobile} {
      //     margin-top: 22px;
      // }
      // @media #{$large-mobile} {
      //     opacity: 1;
      //     visibility: visible;
      // }
    }
  }

  &:hover {
    .content {
      bottom: 0;

      &::before {
        opacity: 0;
      }
    }

    .thumbnail {
      a {
        img {
          transform: scale(1.1);
        }

        &::before {
          opacity: 0;
        }

        &::after {
          opacity: 0.85;
          top: 0;
        }
      }
    }

    .content {
      .blog-btn {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

// ---------- Benifits Card ---------- //
.benifits-card {
  box-shadow: $shadow;

  img {
    width: 100%;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    object-fit: cover;
  }

  .benifits-content {
    flex-direction: column;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: $color-charcoal;
    padding: 50px;
    height: 100%;

    .title-small {
      color: $color-white;
      letter-spacing: 0.5px;
      line-height: 1.5;
    }

    ul {
      padding-left: 20px;

      li {
        color: $color-white;
        padding-bottom: 10px;
      }
    }
  }
}

// ---------- Media Queries ---------- //
@media (max-width: 575.98px) {
  .slick-arrow {
    display: none !important;
  }

  .pulse-icon {
    display: none;
  }

  .video-card-content {
    top: 0px !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    margin-top: 70px !important;

    h2 {
      width: 100% !important;
    }
  }

  .play-video-button {
    right: 0% !important;
  }

  .register-dark-card {
    width: 100%;
    max-width: 80%;
  }

  .video-section {
    height: 930px;
    background-size: 100%;
  }
}

@media (min-width: 768px) {
  .iandyou-card-mobile {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .iandyou-card-section {
    display: none;
  }

  .register-dark-card {
    padding: 50px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .video-section {
    height: 930px;
    background-size: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .video-section {
    height: auto;
    background-size: 100%;
  }
}