@import "../variables/variables";

// 2. Buttons

.btn {
  border-radius: 5px;
  padding: 0.375rem 2rem;
  border: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  &:hover {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
}
.btn-primary {
  background-image: linear-gradient(135deg, #f55555 10%, #fcb431 100%);
  &:focus {
    border: 0;
    box-shadow: unset;
  }
  &:active {
    border: 0;
    box-shadow: unset;
  }
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
  border: 0;
  box-shadow: unset;
}

.btn-secondary {
  background-color: $color-charcoal;
  &:focus {
    border: 0;
    box-shadow: unset;
    background-color: $color-charcoal;
  }
  &:active {
    border: 0;
    box-shadow: unset;
    background-color: $color-charcoal;
  }
  &:hover {
    background-color: $color-charcoal;
  }
}
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  border: 0;
  box-shadow: unset;
}

.custom-button {
  background-image: linear-gradient(135deg, #f55555 10%, #fcb431 100%);
  border-radius: 5px;
  color: #fff;
  border: 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
