@import "../scss/theme/topbar";
@import "../scss/theme/contents";
@import "../scss/theme/about";
@import "../scss/theme/blog";
@import "../scss/theme/typography";
@import "../scss/theme/buttons";
@import "../scss/theme/banner";
@import "../scss/theme/form";
@import "../scss/theme/header";
@import "../scss/theme/card";
@import "../scss/theme/programs";
@import "../scss/theme/footer";

body {
  font-family: "Roboto", sans-serif;
}