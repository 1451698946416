@import "../variables/variables";

.scroll-top {
  right: 15px;
  bottom: 15px;
  color: #fff;
  z-index: 1;
  position: fixed;
  width: 50px;
  height: 50px;
  text-align: center;
  box-shadow: $shadow;
  border-radius: 50%;
  font-size: 20px;
  line-height: 2.3;
  background-image: linear-gradient(135deg, #f55555 10%, #fcb431 100%);

  &:hover {
    box-shadow: $shadow-lg;
  }
}



.footer {
  padding-top: 30px;
  background-color: $color-charcoal;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      display: inline-block;
    }
  }

  ul.socialMediaLinks {
    li {
      padding-right: 20px;
    }
  }

  .footer-logo {
    height: 60px;
  }

  .iso-logo {
    height: 50px;
  }

  .logo-header {
    border-bottom: 1px solid #ffffff17;
    margin-bottom: 20px;
  }

  .footer-logo-text {
    font-weight: 600;
    color: $color-white-light;
    font-size: 20px;
    font-family: "samarkan_____normal";
  }

  .footer-content {
    p {
      color: $color-light-gray;
      font-size: 14px;

      b {
        color: #fff;
      }

      span {
        padding-right: 10px;

        svg {
          margin-top: -5px;
        }
      }
    }

    .menu-links {
      li {
        font-size: 14px;
        display: block;
      }
    }
  }

  .socialMediaLinks {
    li {
      color: $color-light-gray;
      font-size: 20px;
      padding-right: 10px;

      a {
        color: $color-light-gray;
      }
    }
  }

  h2 {
    font-family: $lora;
    font-weight: 600;
    color: $color-white-light;
    font-size: 20px;
    padding: 10px 0;
  }

  .copyright-div {
    padding: 10px 0;
    margin-top: 20px;
    border-top: 1px solid $color-light-gray;

    .left {
      text-align: left;
    }

    .right {
      text-align: right;
    }

    ul {
      li {
        font-size: 12px;
        color: $color-light-gray;
        padding-right: 10px;
      }
    }

    p {
      font-size: 12px;
      margin-bottom: 0;
      color: $color-light-gray;
    }
  }

  @media (max-width: 575.98px) {
    .left {
      text-align: center !important;
    }

    .right {
      text-align: center !important;
    }
  }
}