// colors
$color-white:#fff;
$color-white-light:#ffffffb8;
$color-white-light-1:#645757;

$color-light-gray:#ffffff69;
$color-gray-light:#ced4da;
$color-gray:#fafafa;
$color-light-gray-1:#dddbdb4d;

$color-charcoal:#212121;
$color-light-black:#343a40;

$color-orange:#ef8036;
$color-orange-dark:#f66351;
$color-orange-light:#f66351ad;

$color-bg-1:#f6665017;
$color-bg-2:#a9744712;

// shadow
$shadow-sm:0 .125rem .25rem rgba(0,0,0,.075)!important;
$shadow-lg:0 1rem 3rem rgba(0,0,0,.175)!important;
$shadow:0 .5rem 1rem rgba(0,0,0,.15)!important;

$lora: "Lora", serif;
$nato: "Noto Sans", sans-serif;